/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState, useMemo } from 'react';
import { withTranslation } from 'react-i18next';
import {
    Container,
    Grid,
    Paper,
    Typography,
    withStyles,
    Button,
    Hidden,
    Snackbar,
    useMediaQuery,
    useTheme,
    CircularProgress,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { ChevronLeft } from '@material-ui/icons';
import { withRouter, useHistory } from 'react-router-dom';
import { ClientAuthContext } from '../../Client/Contexts/ClientAuthContext';
import { AdminAuthContext } from '../../Admin/Contexts/AdminAuthContext';
import { COLORS } from '../../utils/Application_Constants';
import TimesheetAccordion from '../../components/ContentComponents/Timesheets/TimesheetAccordion';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { getTimesheetById, saveTimesheet } from '../../services/TimeAndPayService';
import moment from 'moment';
import { cloneDeep } from 'lodash';
import { TimesheetStatusEnumApi } from '../../components/ContentComponents/Timesheets/Status.enum';
import RejectionDialog from './RejectionDialog';
import StatusErrorDialog from './StatusErrorDialog';
import { config as ClientTableConfig } from '../../Client/Configurations/TimesheetReviewConfig';
import { updateTimesheetReview } from '../Services/TimesheetsService';
import StatusWarningDialog from './StatusWarningDialog';
import TimesheetHistory from '../../Admin/Components/TimesheetHistory';
import useQuery from '../../utils/useQuery';
import { formatErrorMessage } from '../Utils/ErrorMessage';
import splitEntriesByBreaks from './SplitEntriesByBreaks';
import { TimesheetUserGroupEnum, TimesheetUserGroupProdEnum } from '../../Admin/Configurations/OktaUserGroup.enum';
import { useDebouncedCallback } from 'use-debounce';

const styles = (theme) => ({
    root: {
        backgroundColor: COLORS.LT_MIDNIGHTBG1,
        minHeight: '200px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    ContainerRoot: {
        paddingTop: '2.5rem',
        paddingBottom: '5rem',
        display: 'flex',
        flexDirection: 'column',
        gap: '24px',
    },
    PaperRoot: {
        padding: '1.5rem',
        display: 'flex',
        gap: '24px',
        flexDirection: 'column',
    },
    containedSuccess: {
        backgroundColor: COLORS.LT_EMERALD,
        '&:hover': {
            backgroundColor: COLORS.LT_EMERALD75,
        },
        color: 'white',
        paddingLeft: '48px',
        paddingRight: '48px',
    },
    approveButton: {
        backgroundColor: COLORS.LT_EMERALD,
        '&:hover': {
            backgroundColor: COLORS.LT_EMERALD75,
        },
        color: 'white',
    },
    recallButton: {
        backgroundColor: COLORS.LT_PEPPER10,
        color: COLORS.LT_PEPPER,
        '&:hover': {
            backgroundColor: COLORS.LT_PEPPER25,
        },
    },
    rejectButton: {
        backgroundColor: COLORS.LT_PEPPER,
        color: 'white',
        '&:hover': {
            backgroundColor: COLORS.LT_PEPPER75,
        },
    },
    overline1: {
        fontWeight: '600',
    },
    overline2: {
        fontSize: '10px',
        fontWeight: '600',
        lineHeight: '18px',
        color: COLORS.LT_MIDNIGHT50,
    },
    body1: {
        lineHeight: '20px',
    },
    warningIcon: {
        marginRight: '35px',
    },
    warningGridWrapper: {
        background: COLORS.LT_SUNFLOWER25,
        padding: '25px 15px',
    },
});

function TimesheetReview(props) {
    const { classes, t } = props;
    const [timesheet, setTimesheet] = useState(null);
    const [viewTimesheet, setViewTimesheet] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [statusErrorDialogOpen, setStatusErrorDialogOpen] = useState(false);
    const [requestedAction, setRequestedAction] = useState();
    const [rejectionDialogOpen, setRejectionDialogOpen] = useState(false);
    const [isRejectedApproved, setIsRejectedApproved] = useState(false);
    const [isTimeNotEntered, setIsTimeNotEntered] = useState(false);
    const [rejectionReason, setRejectionReason] = useState(null);
    const [statusErrorType, setStatusErrorType] = useState('changed');
    const [recallErrorType, setRecallErrorType] = useState();
    const [recallErrorDialogOpen, setRecallErrorDialogOpen] = useState(false);
    const [recallDialogOpen, setRecallDialogOpen] = useState(false);
    const [user, setUser] = useState({});
    const [canSubmit, setCanSubmit] = useState(false);
    const [hideRecall, setHideRecall] = useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'), {
        defaultMatches: true,
    });
    const [alert, setAlert] = useState();
    const history = useHistory();
    let timesheetHasBeenSubmitted = false;
    const locationsToBeSubmitted = [];
    const clientContext = useContext(ClientAuthContext);
    const adminContext = useContext(AdminAuthContext);
    const { id, locationId, clinicianId } = useParams();
    const isAdmin = process.env.REACT_APP_TYPE === 'Admin';
    const [historyEnabled, setHistoryEnabled] = useState(isAdmin);
    const [hasLocation, setHasLocation] = useState(true);
    const canEditTimeStatuses = [
        TimesheetStatusEnumApi.Rejected,
        TimesheetStatusEnumApi.NotStarted,
        TimesheetStatusEnumApi.PendingSubmission,
        TimesheetStatusEnumApi.DidNotWork,
    ];
    const [canEditTime, setCanEditTime] = useState(false);
    const query = useQuery();
    const editable = Boolean(query.get('editable'));
	const isProd = window.location.origin.indexOf('prd-') > -1 ? true : false;

    let userIsReadOnly = true;
	let isTSAdmin = false;

	if (isProd) {
		isTSAdmin = user?.groups?.some((group) => group.includes(TimesheetUserGroupProdEnum.SystemAdministratorPROD)) ||
		user?.groups?.some((group) => group.includes(TimesheetUserGroupProdEnum.TimesheetAdminPROD));
	}
	else {
		isTSAdmin = user?.groups?.some((group) => group.includes(TimesheetUserGroupEnum.SystemAdministrator)) ||
		user?.groups?.some((group) => group.includes(TimesheetUserGroupEnum.TimesheetAdmin));
	}

	if (isTSAdmin || (user && !user.groups)) {
		userIsReadOnly = false;
	}

    for (let i = 0; i < timesheet?.timesheetReviews.length; i++) {
        const timesheetReviewHasBeenSubmitted =
            timesheet?.timesheetReviews[i].status === TimesheetStatusEnumApi.Submitted ||
            timesheet?.timesheetReviews[i].status === TimesheetStatusEnumApi.Approved ||
            timesheet?.timesheetReviews[i].status === TimesheetStatusEnumApi.Paid ||
            timesheet?.timesheetReviews[i].status === TimesheetStatusEnumApi.TimeNotEntered;
        if (timesheetReviewHasBeenSubmitted) {
            timesheetHasBeenSubmitted = true;
        } else {
            const locationId = timesheet?.timesheetReviews[i].locationId;
            locationsToBeSubmitted.push(
                timesheet?.booking?.locations?.filter((location) => location.id === locationId)[0]
                    ?.displayName
            );
        }
    }

    useEffect(() => {
        if (isAdmin && adminContext?.adminUser) {
            setUser(adminContext.adminUser);
        } else if (!isAdmin && clientContext && clientContext?.clientUser) {
            setUser(clientContext.clientUser);
        }
    }, [adminContext, clientContext, isAdmin]);

    useEffect(() => {
        fetchTimesheetById();
    }, []);

    async function fetchTimesheetById() {
        setIsLoading(true);
        try {
            const timesheetDetails = await getTimesheetById(clinicianId, id);
            const viewTimesheetDetails = cloneDeep(timesheetDetails);
            setViewTimesheet(viewTimesheetDetails);
            mapTimeToLocationTimezone(viewTimesheetDetails);
            const getIsRejectedApproved = setStatusValue(timesheetDetails, [
                TimesheetStatusEnumApi.Approved,
                TimesheetStatusEnumApi.Rejected,
            ]);
            const getCanSubmit = setStatusValue(timesheetDetails, [
                TimesheetStatusEnumApi.PendingSubmission,
                TimesheetStatusEnumApi.NotStarted,
            ]);
            const getHideRecall = setRecallValue(timesheetDetails);
            const getIsTimeNotEntered = setStatusValue(timesheetDetails, [
                TimesheetStatusEnumApi.TimeNotEntered,
            ]);
            if (locationId) {
                const locationIds = timesheetDetails.booking.locations.map((location) =>
                    location.id.toString()
                );
                const getHasLocation =
                    locationIds.length > 0 && locationIds.indexOf(locationId) > -1;
                setHasLocation(getHasLocation);
            }
            setCanEditTime(
                canEditTimeStatuses.includes(timesheetDetails.status) && isAdmin && editable
            );
            setIsRejectedApproved(getIsRejectedApproved);
            setCanSubmit(getCanSubmit);
            setHideRecall(getHideRecall);
            setIsTimeNotEntered(getIsTimeNotEntered);
            setTimesheet(timesheetDetails);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.error(error);
        }
    }

    const setRecallValue = (timesheetDetails) => {
        return (
            timesheetDetails.status === TimesheetStatusEnumApi.Paid ||
            timesheetDetails.status === TimesheetStatusEnumApi.Approved
        );
    };

    const setStatusValue = (timesheetDetails, statuses) =>
        locationId
            ? timesheetDetails?.timesheetReviews?.some(
                (tsr) =>
                    tsr.locationId.toString() === locationId && statuses.includes(tsr.status)
            )
            : statuses.includes(timesheetDetails.status);

    const onSubmit = async () => {
        setIsLoading(true);
        const path = `/timesheet/${timesheet.id}/submit`;
        let newTimesheet = timesheet;
        newTimesheet.status = TimesheetStatusEnumApi.Submitted;
        newTimesheet.timesheetEntries = newTimesheet.timesheetEntries.filter(
            (entry) => entry.locationId && entry.rateTypeId
        );
        newTimesheet.mileageEntries = newTimesheet.mileageEntries.filter(
            (entry) => entry.locationId
        );
        try {
            await saveTimesheet(newTimesheet, timesheet.id);
            history.push({
                pathname: path,
                state: { timesheetPreviouslySubmitted: timesheetHasBeenSubmitted },
            });
            setIsLoading(false);
        } catch (error) {
            var message = formatErrorMessage(error);
            if (message === 'An error has occurred.') message = 'Timesheet has failed to be saved. Please try again.';
            setAlert({
                message,
                type: 'error',
            });
            console.error(message);
            setIsLoading(false);
        }
    };

    const debouncedOnSubmit = useDebouncedCallback(() => {
		onSubmit()
	}, 5000, { leading: true });

    const convertUTCtoTime = (utcString, timeZoneBooking) => {
        let utcDateTime = utcString;
        if (utcString !== null) {
            utcDateTime = moment(utcString).tz(timeZoneBooking).format('HH:mm');
        }
        return utcDateTime;
    };
    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setAlert(null);
    };
    const mapTimeToLocationTimezone = (timesheet) => {
        if (!timesheet) return;
        timesheet.timesheetEntries.forEach((timesheetEntry) => {
            const timeZoneBooking =
                timesheet?.booking?.locations?.find(
                    (location) => location.id === timesheetEntry?.locationId
                )?.timeZone || moment.tz.guess();
            let startTime = timesheetEntry.startTime;
            let endTime = timesheetEntry.endTime;
            timesheetEntry.startTime = convertUTCtoTime(startTime, timeZoneBooking);
            timesheetEntry.endTime = convertUTCtoTime(endTime, timeZoneBooking);
            timesheetEntry.transactionDate = timesheetEntry.transactionDate = moment(
                timesheetEntry.transactionDate
            )
                .utc()
                .format('YYYY-MM-DDTHH:mm:ss');

            mapEntryBreakTimesToLocationTimezone(timesheetEntry, timeZoneBooking);
        });
        timesheet.mileageEntries.forEach((mileageEntry) => {
            mileageEntry.transactionDate = moment(mileageEntry.transactionDate)
                .utc()
                .format('YYYY-MM-DDTHH:mm:ss');
        });
        return timesheet;
    };

    const mapEntryBreakTimesToLocationTimezone = (entry, timeZoneBooking) => {
        if (!entry.timesheetBreaks)
            return;

        entry.timesheetBreaks = entry.timesheetBreaks.sort((a, b) => a.startTime.localeCompare(b.startTime));

        entry.timesheetBreaks.forEach((timesheetBreak) => {
            timesheetBreak.startTime = convertUTCtoTime(timesheetBreak.startTime, timeZoneBooking);
            timesheetBreak.endTime = convertUTCtoTime(timesheetBreak.endTime, timeZoneBooking);
        });
    };

    const formatPeriod = (weekEnding) => {
        let endDate = moment(weekEnding).format('MMM D, YYYY');
        let startDate = moment(weekEnding).subtract(6, 'days').format('MMM D');
        return `Week of ${startDate} - ${endDate}`;
    };

    const formatListToCommaSeparated = (locations) => {
        const length = locations.length;

        if (length === 0) {
            return '';
        } else if (length === 1) {
            return locations[0];
        } else if (length === 2) {
            return `${locations[0]} and ${locations[1]}`;
        } else {
            let result = locations.slice(0, -1).join(', ');
            result += `, and ${locations[length - 1]}`;
            return result;
        }
    };

    const getReviewerNames = () => {
        let reviewerFirstName = '';
        let reviewerLastName = '';
        if (user && user?.family_name && user?.given_name) {
            reviewerFirstName = user.given_name;
            reviewerLastName = user.family_name;
        } else if (user && user.name) {
            const names = user.name.split(' ');
            reviewerFirstName = names.slice(0, -1).join(' ');
            reviewerLastName = names[names.length - 1];
        }
        if (user && user.firstName && user.lastName) reviewerFirstName = user.firstName;
        if (user && user.lastName) reviewerLastName = user.lastName;
        return { reviewerFirstName, reviewerLastName };
    };

    const handleStatusUpdate = async (requestedActionEnding) => {
        setIsLoading(true);
        setRequestedAction(requestedActionEnding);
        const { reviewerFirstName, reviewerLastName } = getReviewerNames();
        const reviewToUpdate = timesheet?.timesheetReviews?.find(
            (review) => review?.locationId?.toString() === locationId
        );
        if (!reviewToUpdate) return;
        const timesheetReview = {
            id: reviewToUpdate.id,
            timesheetId: id,
            locationId: locationId,
            status: requestedActionEnding,
            reviewerFirstName,
            reviewerLastName,
            reviewerEmail: user?.email,
            reviewDate: moment().utc().format(),
            rejectionReason: !rejectionReason || rejectionReason === '' ? null : rejectionReason,
            created: reviewToUpdate.created,
            lastModified: reviewToUpdate.lastModified
        };
        try {
            await updateTimesheetReview(id, timesheetReview.id, timesheetReview);

            setIsLoading(false);
            if (
                requestedActionEnding === TimesheetStatusEnumApi.Rejected ||
                requestedActionEnding === TimesheetStatusEnumApi.Approved
            ) {
                history.push(`/timesheet/confirmation/${requestedActionEnding.toLowerCase()}`);
            } else {
                throw new Error('Unexpected Status');
            }
        } catch (error) {
            if (
                error?.errors?.length &&
                error.errors[0].errorCode &&
                ClientTableConfig.errorCodes[error.errors[0].errorCode]
            ) {
                setStatusErrorType(ClientTableConfig.errorCodes[error.errors[0].errorCode]);
                setStatusErrorDialogOpen(true);
            } else {
                const message = formatErrorMessage(error);
                setAlert({
                    message,
                    type: 'error',
                });
            }
            setIsLoading(false);
        }
    };
    const renderStatusErrorDialog = () => {
        const displayAction = statusErrorType === requestedAction ? 'Updated' : requestedAction;

        const props = {
            name: 'status-error',
            message: `This timesheet has been recently ${statusErrorType} and is not available
								to be ${displayAction}.`,
            onClose: () => {
                setStatusErrorDialogOpen(false);
            },
            onExited: () => {
                setRequestedAction();
                history.push(`/timesheets`);
            },
            open: statusErrorDialogOpen,
        };
        return <StatusErrorDialog {...props} />;
    };
    const renderRejectionDialog = () => {
        const props = {
            name: 'status-error',
            message: '*Selecting cancel will NOT reject the timesheet',
            title: 'Reason for rejecting',
            onClose: () => {
                setRejectionDialogOpen(false);
            },
            onExited: () => {
                setRequestedAction();
            },
            handleAction: () => {
                handleStatusUpdate(TimesheetStatusEnumApi.Rejected, timesheet);
                setRejectionDialogOpen(false);
            },
            open: rejectionDialogOpen,
            setRejectionReason: (value) => {
                setRejectionReason(value);
            },
        };
        return <RejectionDialog {...props} />;
    };

    const renderTimesheetPanels = (bookingLocation) => {
        const timesheetEntries = viewTimesheet.timesheetEntries.filter(
            (entry) => entry.locationId === bookingLocation.id
        ).sort((a, b) => new Date(a.transactionDate) - new Date(b.transactionDate));

        // Group timesheetEntries by relatedEntryId or form individual groups for entries without relatedEntryId
        const groups = {};
        timesheetEntries.forEach((entry, index) => {
            if (entry.id === 0) {
                entry.id = -(index + 1);
            }
            const relatedEntryId = entry.relatedEntryId;
            if (relatedEntryId) {
                if (!groups[relatedEntryId]) {
                    groups[relatedEntryId] = [];
                }
                groups[relatedEntryId].push(entry);
            } else {
                // Form individual groups for entries without relatedEntryId
                groups[entry.id] = [entry];
            }
        });

        const sortEntriesWithinGroup = (group) => {
            return group.sort((a, b) => {
                const dateA = new Date(a.transactionDate);
                const dateB = new Date(b.transactionDate);
                if (dateA.getTime() !== dateB.getTime()) {
                    return dateA - dateB;
                }
                // Sort by start time if it exists
                if (a.startTime && b.startTime) {
                    return a.startTime.localeCompare(b.startTime);
                }
                // If start time doesn't exist, maintain the existing order
                return 0;
            });
        };

        //sort groups by the transactionDate of their first entry
        const sortGroupsByTransactionDate = (groups) => {
            return Object.values(groups).sort((a, b) => {
                const dateA = new Date(a[0].transactionDate);
                const dateB = new Date(b[0].transactionDate);
                return dateA - dateB;
            });
        };

        // Sort groups by transactionDate
        const sortedGroups = sortGroupsByTransactionDate(groups);

        // Sort entries within each group
        sortedGroups.forEach(group => {
            sortEntriesWithinGroup(group);
        });

        // Flatten sortedGroups into an array while keeping the order of groups
        let sortedEntries = sortedGroups.flatMap(group => group);
        sortedEntries = splitEntriesByBreaks(sortedEntries);

        const mileageEntries = viewTimesheet.mileageEntries.filter(
            (entry) => entry.locationId === bookingLocation.id
        );

        return (
            <TimesheetAccordion
                key={bookingLocation.id}
                timesheetEntries={sortedEntries}
                mileageEntries={mileageEntries}
                timesheet={viewTimesheet}
                bookingLocation={bookingLocation}
                defaultExpanded={true}
            />
        );
    };

    const renderSuccessSnackbar = () => (
        <Snackbar
            open={Boolean(alert)}
            autoHideDuration={6000}
            onClose={handleAlertClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
            <Alert onClose={handleAlertClose} severity={alert?.type}>
                {alert?.message}
            </Alert>
        </Snackbar>
    );
    const renderRecallDialog = () => {
        const props = {
            name: 'recall-warning',
            message:
                'Recalling a timesheet will recall all submitted locations, stop the approval process, and will require the recalled timesheets to be re-submitted.',
            onClose: () => {
                setRecallDialogOpen(false);
            },
            handleAction: async () => {
                try {
                    setRecallDialogOpen(false);
                    setIsLoading(true);
                    const timesheet = await getTimesheetById(clinicianId, id);
                    setRecallErrorType(timesheet.status.toLowerCase());
                    const currentTimsheetHasEntryInRecallableStatus =
                        timesheet?.timesheetReviews?.some(
                            (review) => review.status === TimesheetStatusEnumApi.Submitted
                        );
                    if (!currentTimsheetHasEntryInRecallableStatus) {
                        setRecallErrorDialogOpen(true);
                        setIsLoading(false);
                    } else {
                        timesheet.status = TimesheetStatusEnumApi.PendingSubmission;
                        await saveTimesheet(timesheet, id, true);
                        history.push(
                            `/timesheet/${timesheet.id}/edit?weekEnding=${timesheet?.weekEnding}&clinicianId=${timesheet?.clinicianId}`
                        );
                    }
                } catch (error) {
                    setAlert({
                        message: `Timesheet has failed to be recalled. Please try again.`,
                        type: 'error',
                    });
                    setIsLoading(false);
                    console.error(error);
                }
            },
            open: recallDialogOpen,
            primaryActionName: 'Recall',
            secondaryActionName: 'Cancel',
        };
        return <StatusWarningDialog {...props} />;
    };
    const renderRecallErrorDialog = () => {
        const props = {
            name: 'recall-error',
            message: `This timesheet has been recently ${recallErrorType} and is not available
								to be recalled. Please reach out to your recruiter with any
								questions.`,
            onClose: () => {
                setRecallErrorDialogOpen(false);
                setIsLoading(false);
            },
            onExited: () => {
                setRequestedAction();
                history.push(`/timesheets`);
            },
            open: recallErrorDialogOpen,
        };
        return <StatusErrorDialog {...props} />;
    };

    const renderTimesheetRejectionReasons = () => {
        const rejectedTimesheetReviews = timesheet?.timesheetReviews?.filter(
            (timesheetReview) => timesheetReview.status === TimesheetStatusEnumApi.Rejected
        );

        return (
            <>
                {rejectedTimesheetReviews.map((rejectedTimesheet, i) => {
                    const rejectedComment = rejectedTimesheet?.rejectionReason;
                    let reviewer = timesheet?.booking?.locations?.find(
                        (location) => rejectedTimesheet?.locationId === location.id
                    )?.displayName;
                    return (
                        <Alert
                            severity='error'
                            className={
                                i === rejectedTimesheetReviews.length - 1
                                    ? classes.lastRejectionReasonAlert
                                    : classes.rejectReasonAlert
                            }
                        >
                            <Grid container alignItems='center'>
                                {reviewer?.length && reviewer?.length > 0
                                    ? `${t('submitTimesheet:HEADERS.TIMESHEET_REJECTED_BY')} ${reviewer || ''
                                    }. ${t('submitTimesheet:HEADERS.TIMESHEET_REJECTED')}`
                                    : `${t('submitTimesheet:HEADERS.TIMESHEET_WAS_REJECTED')} ${rejectedComment?.length && rejectedComment?.length > 0
                                        ? t(
                                            'submitTimesheet:HEADERS.CHECK_FACILITY_COMMENT'
                                        )
                                        : ''
                                    }`}
                            </Grid>
                            {rejectedComment?.length > 0 && (
                                <>
                                    <ul className={classes.errorListItem}>
                                        <li>
                                            <Grid container alignItems='center'>
                                                <Typography
                                                    variant='body2'
                                                    classes={{ root: classes.errorText }}
                                                >
                                                    Comment from the facility: "
                                                    {rejectedTimesheet?.rejectionReason || ''}"
                                                </Typography>
                                            </Grid>
                                        </li>
                                    </ul>
                                </>
                            )}
                        </Alert>
                    );
                })}
            </>
        );
    };

    const getRejectReason = useMemo(() => {
        return timesheet?.timesheetReviews?.map((timesheetReview) => {
            if (
                timesheetReview?.locationId?.toString() === locationId &&
                timesheetReview?.status === TimesheetStatusEnumApi.Rejected
            ) {
                return <Grid item>{renderTimesheetRejectionReasons()}</Grid>;
            }
        });
    }, [timesheet, locationId]);

    const renderNavigationButton = () => {
        return (
            <>
                {canEditTime ? (
                    <Button
                        variant='text'
                        color='primary'
                        startIcon={<ChevronLeft />}
                        onClick={() =>
                            history.push(
                                `/timesheet/${timesheet.id}/edit?weekEnding=${timesheet.weekEnding}&clinicianId=${clinicianId}`
                            )
                        }
                    >
                        <Typography variant='overline' className={classes.overline1}>
                            {t('submitTimesheet:LINKS.GO_BACK_TO_EDIT_TIME')}
                        </Typography>
                    </Button>
                ) : (
                    <Button
                        variant='text'
                        color='primary'
                        startIcon={<ChevronLeft />}
                        onClick={() => history.push(`/timesheets?page=1`)}
                    >
                        <Typography variant='overline' className={classes.overline1}>
                            {t('submitTimesheet:LINKS.GO_BACK_TO_ALL_TIMESHEETS')}
                        </Typography>
                    </Button>
                )}
            </>
        );
    };

    const renderClientReviewPage = () => {
        return (
            <Container maxWidth='xl' className={classes.ContainerRoot}>
                {renderSuccessSnackbar()}
                <Grid container direction='row' spacing={isMobile ? 2 : 4}>
                    <Grid item xs={12} md={3}>
                        <Grid container direction='column' spacing={isMobile ? 0 : 2}>
                            <Grid item>{renderNavigationButton()}</Grid>
                            <Grid item>
                                <Typography
                                    variant='h3'
                                    component='h3'
                                    gutterBottom
                                    className={classes.header}
                                >
                                    {t('submitTimesheet:HEADERS.REVIEW_AND_REJECT_APPROVE')}
                                    {timesheet?.clinicianName}
                                </Typography>
                                <Typography
                                    variant='subtitle1'
                                    gutterBottom
                                    className={classes.header}
                                >
                                    {timesheet?.booking.name}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={9}>
                        <Grid container direction='column' spacing={4}>
                            {timesheetHasBeenSubmitted &&
                                timesheet.status === TimesheetStatusEnumApi.PendingSubmission &&
                                !locationId && (
                                    <Grid item xs={12}>
                                        <Alert severity='warning'>
                                            {t('submitTimesheet:HEADERS.ALREADY_SUBMITTED_WARNING')}
                                            {formatListToCommaSeparated(locationsToBeSubmitted)}
                                        </Alert>
                                    </Grid>
                                )}
                            {getRejectReason}
                            <Grid item xs={12}>
                                <Paper className={classes.PaperRoot}>
                                    <Typography variant='h5' className={classes.header}>
                                        {formatPeriod(timesheet?.weekEnding)}
                                    </Typography>
                                    {hasLocation && (
                                        <>
                                            {timesheet?.booking.locations.map((location) => {
                                                if (
                                                    locationId &&
                                                    location?.id?.toString() !== locationId
                                                )
                                                    return <></>;
                                                return renderTimesheetPanels(location);
                                            })}
                                            {historyEnabled && (
                                                <Grid container justifyContent='flex-end'>
                                                    <Grid item>
                                                        <TimesheetHistory locationId={locationId} />
                                                    </Grid>
                                                </Grid>
                                            )}
                                        </>
                                    )}
                                    {!hasLocation && (
                                        <Typography variant='body2'>
                                            No available data for this location.
                                        </Typography>
                                    )}
                                </Paper>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {hasLocation && (
                    <Grid container justifyContent='flex-end' spacing={2}>
                        <Hidden
                            only={
                                isRejectedApproved || userIsReadOnly || !canSubmit
                                    ? ['xs', 'sm', 'md', 'lg', 'xl']
                                    : []
                            }
                        >
                            <Grid item>
                                <Button
                                    onClick={debouncedOnSubmit}
                                    variant='contained'
                                    className={classes.containedSuccess}
                                >
                                    {t('submitTimesheet:BUTTONS.SUBMIT')}
                                </Button>
                            </Grid>
                        </Hidden>
                        <Hidden
                            only={
                                isRejectedApproved || userIsReadOnly || canSubmit || hideRecall
                                    ? ['xs', 'sm', 'md', 'lg', 'xl']
                                    : []
                            }
                        >
                            {isAdmin && (
                                <Grid item>
                                    <Button
                                        onClick={() => {
                                            setRecallDialogOpen(true);
                                        }}
                                        variant='contained'
                                        className={classes.recallButton}
                                    >
                                        {t('submitTimesheet:BUTTONS.RECALL')}
                                    </Button>
                                </Grid>
                            )}
                            {!isTimeNotEntered && (
                                <>
                                    <Grid item>
                                        <Button
                                            onClick={() => {
                                                setRejectionDialogOpen(true);
                                            }}
                                            variant='contained'
                                            className={classes.rejectButton}
                                        >
                                            {t('submitTimesheet:BUTTONS.REJECT')}
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            onClick={() => {
                                                setRequestedAction(TimesheetStatusEnumApi.Approved);
                                                handleStatusUpdate(TimesheetStatusEnumApi.Approved);
                                            }}
                                            variant='contained'
                                            className={classes.approveButton}
                                        >
                                            {t('submitTimesheet:BUTTONS.APPROVE')}
                                        </Button>
                                    </Grid>
                                </>
                            )}
                        </Hidden>
                    </Grid>
                )}
                {renderStatusErrorDialog()}
                {renderRejectionDialog()}
                {renderRecallDialog()}
                {renderRecallErrorDialog()}
            </Container>
        );
    };

    return (
        <div className={classes.root}>
            {isLoading ? (
                <CircularProgress classes={{ root: classes.progressRoot }} color='primary' />
            ) : (
                renderClientReviewPage()
            )}
        </div>
    );
}

export default withRouter(withTranslation()(withStyles(styles)(TimesheetReview)));
